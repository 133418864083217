import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import { DicomExternalQueriesColumns } from '../../constants';

const DicomExternalQueries = ({ dicomModel }) => (
  <Stack
    direction="column"
    spacing={4}
    sx={{ marginTop: 5 }}
  >
    <FingoDataGrid
      rows={dicomModel?.externalQueries ?? []}
      columns={DicomExternalQueriesColumns}
      serverFilters={false}
      sx={{ minHeight: 250 }}
      hideFooter
    />
  </Stack>
);

DicomExternalQueries.propTypes = {
  dicomModel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    externalQueries: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      businessName: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      use: PropTypes.string.isRequired,
      platform: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};

export default DicomExternalQueries;
