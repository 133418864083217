import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, List, ListItem } from '@mui/material';
import { ActionListItem } from '../lists';

const CollectionActions = ({ actions }) => (
  <List>
    {actions.map((action) => (
      <Box key={action.id}>
        <ListItem sx={{ marginBottom: 1 }}>
          <ActionListItem action={action} documentsType={action.invoices ? 'invoices' : 'purchaseorders'} />
        </ListItem>
        <Divider />
      </Box>
    ))}
  </List>
);

CollectionActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
};

CollectionActions.defaultProps = {
  actions: [],
};

export default CollectionActions;
