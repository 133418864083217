import { Avatar, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useGetInvoice } from '../../hooks';
import { AttachedDocuments } from '../attach';
import FingoDialog from './FingoDialog';

const InvoiceProfileDialog = ({ invoiceId, open, setOpen }) => {
  const invoice = useGetInvoice(invoiceId);
  const closeDialog = useCallback(() => setOpen(false), []);
  return (
    <FingoDialog
      open={open}
      handleClose={closeDialog}
      title={invoice ? `Factura ${invoice.folio} de ${invoice.company.masterEntity.name}` : ''}
      fullWidth
    >
      {invoice ? (
        <Grid container direction="column">
          <Divider sx={{ marginBlock: 1 }}>Equipo Fingo</Divider>
          <Grid container direction="row" alignItems="center">
            <Avatar
              alt={
                invoice.company.executiveAssigned
                  ? invoice.company.executiveAssigned?.firstName
                  : ''
              }
              src={
                invoice.company.executiveAssigned
                  ? invoice.company.executiveAssigned?.picture?.url
                  : ''
              }
              sx={{ margin: 1 }}
            />
            <Typography inline>
              {invoice.company.executiveAssigned?.firstName} es su ejecutivo/a
            </Typography>
          </Grid>
          {invoice.collectionManager?.collector && (
            <Grid container direction="row" alignItems="center">
              <Avatar
                alt={invoice.collectionManager?.collector?.firstName || ''}
                src={invoice.collectionManager?.collector?.picture?.url || ''}
                sx={{ margin: 1 }}
              />
              <Typography inline>
                {invoice.collectionManager.collector
                  ? `${invoice.collectionManager.collector.firstName} a cargo de la cobranza`
                  : 'Sin cobradora asignada'}
              </Typography>
            </Grid>
          )}

          <Divider sx={{ marginBlock: 1 }}>Documentos descargables</Divider>
          <AttachedDocuments invoiceId={invoice.id} />
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </FingoDialog>
  );
};

InvoiceProfileDialog.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default InvoiceProfileDialog;
