import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import { DicomScoresColumns } from '../../constants';

const DicomScore = ({ dicomModel }) => (
  <Stack
    direction="column"
    spacing={4}
    sx={{ marginTop: 5 }}
  >
    <FingoDataGrid
      rows={dicomModel?.dicomScores ?? []}
      columns={DicomScoresColumns}
      serverFilters={false}
      sx={{ minHeight: 250 }}
      hideFooter
    />
  </Stack>
);

DicomScore.propTypes = {
  dicomModel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    dicomScores: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      score: PropTypes.string.isRequired,
    })).isRequired,
  }),
};

DicomScore.defaultProps = {
  dicomModel: {},
};

export default DicomScore;
