import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { LegalRepresentativeColumns } from '../../constants';
import { COMPANY_LEGAL_REPRESENTATIVES, UPDATE_BUREAUS } from '../../graphql';
import { useSnackBars } from '../../hooks';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import FingoDialog from './FingoDialog';

const LegalRepresentativesDialog = ({ companyId }) => {
  const { addAlert } = useSnackBars();
  const [open, setOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [loadLR, { data, loading }] = useLazyQuery(COMPANY_LEGAL_REPRESENTATIVES, {
    variables: { masterEntityId: companyId },
    onCompleted: () => {
      setOpen(true);
    },
  });
  const legalRepresentative = data?.getMasterEntity?.company?.legalRepresentative || [];
  const [updateDicoms, { loading: loadingDicom }] = useMutation(
    UPDATE_BUREAUS,
    {
      variables: {
        ids: legalRepresentative.filter(
          (entity) => selectedCompanies.includes(entity.id),
        ).map((entity) => entity.relatedPerson.id),
        updateDicom: true,
        updateBusinessProfile: false,
        updateCurrentBehaviour: false,
        delay: false,
      },
      refetchQueries: [COMPANY_LEGAL_REPRESENTATIVES],
      onCompleted: () => addAlert({
        severity: 'success',
        message: 'Dicoms actualizados correctamente',
      }),
    },
  );
  return (
    <>
      <LoadingButton
        variant="contained"
        size="small"
        color="primary"
        sx={{ width: '40%' }}
        onClick={loadLR}
        loading={loading}
      >
        Dicom Socios
      </LoadingButton>
      <FingoDialog
        open={open}
        title="Dicom Socios"
        handleClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <Stack direction="column">
          <Stack direction="row">
            <LoadingButton
              variant="contained"
              disabled={selectedCompanies.length === 0}
              loading={loadingDicom}
              onClick={updateDicoms}
              sx={{ width: '20%' }}
            >
              Actualizar Dicom
            </LoadingButton>
          </Stack>
          <Stack>
            <FingoDataGrid
              checkboxSelection
              rows={legalRepresentative}
              onSelectionModelChange={(ids) => setSelectedCompanies(ids)}
              columns={LegalRepresentativeColumns}
              hideFooter
            />
          </Stack>
        </Stack>
      </FingoDialog>
    </>
  );
};

LegalRepresentativesDialog.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default LegalRepresentativesDialog;
