import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Stack } from '@mui/material';
import moment from 'moment';
import { actionTypeToSpanish } from '../../helpers';

const UserActions = ({ actions }) => (
  <>
    {actions.length > 0
      ? (
        <Grid container sx={{ px: 1, my: 1 }}>
          <Grid item xs={3}>
            <Typography>
              Fecha
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              Gestión
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h2" sx={{ pl: 1, my: 1 }}>
          No hay gestiones disponibles
        </Typography>
      )}

    {actions.length > 0 && actions.map((action) => (
      <Grid
        key={action.id}
        container
        sx={{ py: 1.5,
          backgroundColor: (theme) => theme.palette.background.light,
          borderRadius: 2,
          px: 1,
          my: 1,
          alignItems: 'center' }}
      >
        <Grid item xs={3}>
          <Stack>
            <Typography>
              {moment(action.createdAt).format('DD-MM-YYYY')}
            </Typography>
            <Typography variant="caption" sx={{ fontSize: 'sm', mt: 0.1 }}>
              {moment(action.createdAt).format('HH:ss')} hrs
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {actionTypeToSpanish[action.actionType]}
          </Typography>
        </Grid>
      </Grid>
    ))}

  </>
);

UserActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array,
};

UserActions.defaultProps = {
  actions: [],
};

export default UserActions;
