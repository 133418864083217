import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Collapse } from '@mui/material';

const FingoAlert = ({ alert, setAlert, ...alertProps }) => {
  useEffect(() => {
    setTimeout(() => {
      setAlert((prev) => ({ ...prev, show: false }));
    }, alert.timeout);
  }, [alert]);
  return (
    <Collapse in={alert.show}>
      {alert.show && (
        <Alert
          severity={alert.severity}
          variant="filled"
          sx={{ width: '100%', borderRadius: 0, ...alertProps }}
        >
          {alert.message}
        </Alert>
      )}
    </Collapse>
  );
};

FingoAlert.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string,
    severity: PropTypes.string,
    show: PropTypes.bool,
    timeout: PropTypes.number,
  }),
  setAlert: PropTypes.func.isRequired,
};

FingoAlert.defaultProps = {
  alert: { message: '', severity: '', show: false, timeout: 3600000 },
};

export default FingoAlert;
