import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import { DicomSocietiesColumns } from '../../constants';

const DicomSocieties = ({ dicomModel }) => (
  <Stack
    direction="column"
    spacing={4}
    sx={{ marginTop: 5 }}
  >
    <FingoDataGrid
      rows={dicomModel?.societies ?? []}
      columns={DicomSocietiesColumns}
      serverFilters={false}
      hideFooter
      sx={{ minHeight: 250 }}
    />
  </Stack>
);

DicomSocieties.propTypes = {
  dicomModel: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    societies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      rut: PropTypes.string,
      dv: PropTypes.string,
      name: PropTypes.string,
      capital: PropTypes.string,
      beginDate: PropTypes.string,
    })),
  }),
};

DicomSocieties.defaultProps = {
  dicomModel: {},
};

export default DicomSocieties;
