import React from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer } from '@mui/material';

const ActionsDrawer = ({
  actions,
  open,
  setOpen,
  title,
  subtitle,
  headerTooltipText,
  headerComponent: HeaderComponent,
  contentComponent: ContentComponent,
  width,
  ...props }) => (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      anchor="right"
      PaperProps={{
        sx: {
          width,
        },
      }}
    >
      <Box
        sx={{ padding: 5, width: '100%', height: '100%', backgroundColor: 'white' }}
      >
        <HeaderComponent subtitle={subtitle} title={title} headerTooltipText={headerTooltipText} />
        <ContentComponent actions={actions} {...props} open={open} />
      </Box>
    </Drawer>
);

ActionsDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  headerTooltipText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  headerComponent: PropTypes.func,
  contentComponent: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ActionsDrawer.defaultProps = {
  actions: [],
  headerComponent: null,
  contentComponent: null,
  subtitle: '',
  headerTooltipText: null,
  width: 600,
};

export default ActionsDrawer;
