import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Divider, List, ListItem, Typography, Button, TextField } from '@mui/material';
import moment from 'moment';
import { formatDateWordsWithYear, formatTime } from '../../helpers/date';
import { ADD_EVALUATION_COMMENT, MASTER_ENTITY_PROFILE } from '../../graphql/customers';

const MasterEntityNotesComponent = ({ actions, masterEntityId }) => {
  const [text, setText] = useState('');
  const [showAddComment, setShowAddComment] = useState(false);

  const [addCompanyComment, { loading: addingComment }] = useMutation(
    ADD_EVALUATION_COMMENT,
    {
      variables: {
        comment: text,
        masterEntity1: masterEntityId,
        masterEntity2: null,
      },
      awaitRefetchQueries: true,
      refetchQueries: [MASTER_ENTITY_PROFILE],
      onCompleted: () => {
        setText('');
        setShowAddComment(false);
      },
    },
  );
  return (
    <>
      <List>
        {actions.map((comment) => (
          <>
            <ListItem sx={{ marginBottom: 0.5 }}>
              <Typography variant="h5" color="primary">
                {comment.user.firstName} {comment.user.lastName}
              </Typography>
            </ListItem>
            <ListItem sx={{ marginBottom: 0.5 }}>
              <Typography variant="body1" whiteSpace="pre-line">
                {comment.comments}
              </Typography>
            </ListItem>
            <ListItem sx={{ marginBottom: 0.5 }}>
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                {`${formatDateWordsWithYear(moment(comment.createdAt))} a 
                las ${formatTime(moment(comment.createdAt))}`}
              </Typography>
            </ListItem>
            <Divider />
          </>
        ))}
        {!showAddComment ? (
          <ListItem sx={{ marginTop: 1 }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => { setShowAddComment(true); }}
            >
              Agregar Comentario
            </Button>
          </ListItem>
        ) : (
          <>
            <ListItem>
              <TextField
                sx={{ width: '100%' }}
                variant="outlined"
                name="rut"
                value={text}
                onChange={(e) => setText(e.target.value)}
                multiline
                rows={3}
              />
            </ListItem>
            <ListItem sx={{ marginLeft: '25%' }}>
              <LoadingButton
                sx={{ marginRight: '1%' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={addCompanyComment}
                disabled={!text}
                loading={addingComment}
              >
                Guardar
              </LoadingButton>
              <Button
                sx={{ marginLeft: '1%' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => { setShowAddComment(false); }}
              >
                Cancelar
              </Button>
            </ListItem>
          </>
        )}
      </List>
    </>
  );
};

MasterEntityNotesComponent.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  masterEntityId: PropTypes.string.isRequired,
};

export default MasterEntityNotesComponent;
